import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "./GlobalContext";
import axios from 'axios';

export default function Navigation() {
    const navigate = useNavigate();
    const {setUser} = useContext(GlobalContext);

    const handleLogout = async() => {
        localStorage.clear();
        setUser('');
        navigate('/');
        await axios.post(`${process.env.REACT_APP_API_URL}/logout`);
    }

    return (
        <nav className="navbar navbar-expand-lg bg-primary w-100" data-bs-theme="dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/dashboard">Inventory Management</a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="/dashboard">Dashboard</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/moditems">Edit inventory</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/viewitems">Find item/Check Stocks</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/downloads">Download Resources</a>
                        </li>
                    </ul>
                    
                    <form className="d-flex">
                        <button className="btn btn-success" onClick={handleLogout}>Logout</button>
                    </form>
                </div>
            </div>
        </nav>
    );
}