import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../GlobalContext';
import '../styles/auth.css';

export default function Login() {
    const { setUser } = useContext(GlobalContext);
    const [log_user, setLog_User] = useState('');
    const [pass, setPass] = useState('');
    const [msg, setMsg] = useState('');
    const [disp, setDisp] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleDetails = (e, t) => {
        setDisp(false);
        setMsg('');
        if (t === 'user') {
            setLog_User(e.target.value);
        }
        else {
            setPass(e.target.value);
        }
    }

    const handleLog = async () => {
        if (pass !== '' && log_user !== '') {
            try {
                localStorage.clear();
                await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                    username: log_user,
                    password: pass
                }, { withCredentials: true });
                localStorage.setItem("user", `${log_user.slice(0, log_user.indexOf("@"))}`);
                setUser(log_user.slice(0, log_user.indexOf("@")));
                setLoading(true);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 3000);
            }
            catch (err) {
                setDisp(true);
                if (err.message.includes('401')) {
                    setMsg('Invalid user or password');
                }
                else if (err.message.includes('404')) {
                    setMsg('User does not exist');
                }
            }
        }
        else {
            setDisp(true);
            setMsg("Please enter all details");
        }
    }


    return (
        <div className='auth-container'>
            <div className='auth-card-container'>
                <h1 className='auth-title'>Login</h1>
                <input id='email' type='email' placeholder='johndoe@gmail.com' onChange={(e) => handleDetails(e, 'user')} className='auth-inputs' />
                <input id='password' type='password' placeholder='password' onChange={handleDetails} className='auth-inputs' />
                <button onClick={handleLog} className='auth-btns'>Login</button>
                <a href='/signup'>Signup</a>
                {loading ?
                    <div className="spinner-border text-dark" role="status" style={{marginTop: "5vh"}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    : null
                }
                {disp && (
                    <div className="alert alert-danger auth-alerts-container" role="alert">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="" viewBox="0 0 16 16" role="img" aria-label="Warning:" style={{ height: "3.5vh", marginRight: "1vw" }}>
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div style={{ display: "inline-block"}}>
                            {msg}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}