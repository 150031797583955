import React from "react";
import '../styles/extras.css';

export default function NotFound() {
    return (
        <div className="forbid">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>Sorry, the page you are looking for does not exist.</p>
            <a href="/signup">Go to Signup</a>
            <a href="/" style={{marginTop: "20px"}}>Login</a>
        </div>
    );
}