import React from "react";
import '../styles/extras.css';

export default function Forbidden() {
    return (
        <div className="forbid">
            <h1>401</h1>
            <h2>Unauthorized</h2>
            <p>Sorry, you need to log in to view this page.</p>
            <a href="/" style={{marginBottom: "20px"}}>Login</a>
            <a href="/signup">Go to Signup</a>
        </div>
    );
}