import React, { useContext, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import axios from 'axios';
import '../styles/viewinven.css';

export default function ViewItem() {
    const { user } = useContext(GlobalContext);
    const [msg, setMsg] = useState('');
    const [disp, setDisp] = useState(false);
    const [SKU, setSKU] = useState('');
    const [fitm, setFitm] = useState(null);
    const [litms, setLitms] = useState(null);
    const [limit, setLimit] = useState(0);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const handleFind = async () => {
        if (SKU) {
            try {
                const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/grab/item/${user}/${SKU}`, {
                    withCredentials: true
                });
                setFitm('');
                if (resp.data.item) {
                    setFitm(resp.data.item);
                }

            }
            catch (err) {
                if (err.message.includes('403')) {
                    navigate('/unauth') ;
                }
                else {
                    setMsg("Internal Server Error");
                }
            }
        }
        else {
            setDisp(true);
            setMsg("Please fill all the necessary fields");
        }
    }

    const handleCheck = async () => {
        if (limit) {
            try {
                const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/check/low/${user}/${limit}`, {
                    withCredentials: true
                });
                setLitms([]);
                if (resp.data.low) {
                    setLitms(resp.data.low);
                }
            }
            catch (err) {
                if (err.message.includes('403')) {
                    navigate('/unauth');
                }
                else {
                    setMsg("Internal Server Error");
                }
            }
        }
        else {
            setDisp(true);
            setMsg("Please fill all the required fields");
        }
    }

    const handlePg = (e,val) => {
        e.preventDefault();
        setPage(val);
    }

    return (
        <div>
            <Navigation />

            <div className="view-container">
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className="page-item"><a className="page-link" onClick={(e) => handlePg(e,1)} href="#">Find Item</a></li>
                        <li className="page-item"><a className="page-link" onClick={(e) => handlePg(e,2)} href="#">Check Stocks</a></li>
                    </ul>
                </nav>

                {page === 1 ?
                    <div className="view-container">
                        <button onClick={handleFind} className="btn btn-success view-btn-spacing">Find Item</button>
                        <input type="text" placeholder="SKU" onChange={(e) => { setSKU(e.target.value); setFitm(null); setDisp(false);}} className="form-control" style={{width:"35vh"}}/>

                        {fitm === null ? null :
                            fitm === '' ?
                                <h5 style={{ marginTop: "4vh", fontWeight: "bold" }}>No such item found</h5>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>SKU</th>
                                            <th>Description</th>
                                            <th>Stocks</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{fitm.item}</td>
                                            <td>{fitm.SKU}</td>
                                            <td>{fitm.desc}</td>
                                            <td>{fitm.stock}</td>
                                            <td>{fitm.price}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        }

                    </div>
                    : null
                }

                {page === 2 ?
                    <div className="view-container">
                        <button onClick={handleCheck} className="btn btn-success view-btn-spacing">Check low stocks</button>
                        <input type="number" placeholder="Enter stocks quantity" onChange={(e) => { setLimit(e.target.value); setLitms(null); setDisp(false);}} className="form-control" style={{width:"40vh"}}/>

                        {litms === null ? null :
                            litms.length === 0 ?
                                <h5 style={{ marginTop: "5vh", fontWeight: "bold" }}>All items have a stock of greater than {limit} units</h5>
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>SKU</th>
                                            <th>Stocks</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {litms.map((itm,index) => (
                                            <tr key={index}>
                                                <td>{itm.item}</td>
                                                <td>{itm.SKU}</td>
                                                <td>{itm.stock}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                        }
                    </div>
                    : null}

                <h5 style={{ marginTop: "5vh", fontWeight: "bold" }}>{disp ? msg : null}</h5>

            </div>

        </div>
    );
}