import React, { useContext, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import Navigation from "../Navigation";
import axios from 'axios';
import '../styles/modinven.css';

export default function ItemAction() {
    const { user } = useContext(GlobalContext);
    const [item, setItem] = useState('');
    const [SKU, setSKU] = useState('');
    const [oldSKU, setOldSKU] = useState('');
    const [desc, setDesc] = useState('');
    const [stk, setStk] = useState('');
    const [price, setPrice] = useState('');
    const [disp, setDisp] = useState(false);
    const [msg, setMsg] = useState(null);
    const [file, setFile] = useState(null);

    const [page, setPage] = useState(1);

    const apiRequest = async (url, payload, method) => {
        try {
            if (method === 'delete') {
                await axios[method](url, { withCredentials: true });
            }
            else if (payload instanceof FormData) {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/read/csv/${user}`, payload, { withCredentials: true, });
            }
            else {
                await axios[method](url, payload, { withCredentials: true });
            }
            return true;
        } catch (err) {
            if (err.message.includes('404')) {
                setMsg("Item does not exist");
            } else {
                setMsg(err.message);
            }
            return false;
        }
    }

    const modifyInventory = async (action) => {
        setDisp(true);
        setMsg(null);
        let resp;
        switch (action) {
            case 'add':
                if (item && SKU && desc && stk && price) {
                    resp = apiRequest(`${process.env.REACT_APP_API_URL}/api/add/item/${user}`, {
                        item: item, SKU: SKU, desc: desc, stock: stk, price: price
                    }, 'post');
                    if (resp) {
                        setMsg("Added the item sucessfully")
                    };
                }
                else {
                    setMsg("Please fill all the necessary fields");
                }
                break;
            case 'addmulti':
                if (file) {
                    const formData = new FormData();
                    formData.append('file', file);
                    const resp = apiRequest(`${process.env.REACT_APP_API_URL}/api/read/csv/${user}`, formData, 'post');
                    if (resp) {
                        setMsg("Added items successfully");
                    }
                }
                else {
                    setMsg("Please enter a csv file with the name: file");
                }
                break;
            case 'remove':
                if (SKU) {
                    resp = apiRequest(`${process.env.REACT_APP_API_URL}/api/remove/item/${user}/${SKU}`, {}, 'delete');
                    if (resp) {
                        setMsg("Removed item successfully");
                    }
                }
                else {
                    setMsg("Please fill all the necessary fields");
                }
                break;
            case 'modify':
                if (oldSKU && item && SKU && price && desc) {
                    resp = apiRequest(`${process.env.REACT_APP_API_URL}/api/modify/item/${user}`, {
                        oldSKU: oldSKU, item: item, SKU: SKU, desc: desc, stock: stk, price: price
                    }, 'put');
                    if (resp) {
                        setMsg("Modified item successfully");
                    }
                }
                else {
                    setMsg("Please fill all the necessary fields");
                }
                break;
            default:
                if (SKU && stk) {
                    resp = apiRequest(`${process.env.REACT_APP_API_URL}/api/update/stock/${user}`, {
                        SKU: SKU, stock: stk
                    }, 'put');
                    if (resp) {
                        setMsg("Updated item stock successfully");
                    }
                }
                else {
                    setMsg("Please fill all the necessary fields");
                }
        }
    }

    const handlePage = (e, val) => {
        e.preventDefault();
        setPage(val);
    }

    return (
        <div>
            <Navigation />

            <div className="iaction-container">
                <nav aria-label="Page navigation">
                    <ul className="pagination">
                        <li className="page-item"><a className="page-link" href="#" onClick={(e) => handlePage(e, 1)}>Add Item</a></li>
                        <li className="page-item"><a className="page-link" href="#" onClick={(e) => handlePage(e, 2)}>Delete Item</a></li>
                        <li className="page-item"><a className="page-link" href="#" onClick={(e) => handlePage(e, 3)}>Modify Item</a></li>
                        <li className="page-item"><a className="page-link" href="#" onClick={(e) => handlePage(e, 4)}>Update Stocks</a></li>
                    </ul>
                </nav>

                {page === 1 ?
                    <div className="iaction-container">
                        <div className="iaction-add-container">
                            <div>
                                <button onClick={() => modifyInventory('add')} className="btn btn-success iaction-btn-spacing">Add item</button>
                                <input type="text" placeholder="item" onChange={(e) => setItem(e.target.value)} className="form-control iaction-inpt-spacing" />
                                <input type="text" placeholder="SKU" onChange={(e) => setSKU(e.target.value)} className="form-control iaction-inpt-spacing" />
                                <textarea type="text" placeholder="description" onChange={(e) => setDesc(e.target.value)} className="form-control iaction-inpt-spacing" />
                                <input type="number" placeholder="stock" onChange={(e) => setStk(e.target.value)} className="form-control iaction-inpt-spacing" />
                                <input type="number" step={0.01} min={0} placeholder="price" onChange={(e) => setPrice(e.target.value)} className="form-control" />
                            </div>

                            <div>
                                <button className="btn btn-success iaction-btn-spacing" onClick={() => modifyInventory('addmulti')}>Add items</button>
                                <br />
                                <input type="file" accept=".csv" onChange={(e) => setFile(e.target.files[0])} />
                            </div>
                        </div>
                    </div>
                    : null
                }


                {page === 2 ?
                    <div className="iaction-container">
                        <button onClick={() => modifyInventory('remove')} className="btn btn-success iaction-btn-spacing">Remove item</button>

                        <input type="text" placeholder="SKU" onChange={(e) => setSKU(e.target.value)} className="form-control" />
                    </div>
                    : null
                }


                {page === 3 ?
                    <div className="iaction-container">
                        <button onClick={() => modifyInventory('modify')} className="btn btn-success iaction-btn-spacing">Update item</button>
                        <h5>Old item</h5>
                        <input type="text" placeholder="SKU" onChange={(e) => setOldSKU(e.target.value)} className="form-control iaction-inpt-spacing" />
                        <h5>Modify details</h5>
                        <input type="text" placeholder="item" onChange={(e) => setItem(e.target.value)} className="form-control iaction-inpt-spacing" />
                        <input type="text" placeholder="SKU" onChange={(e) => setSKU(e.target.value)} className="form-control iaction-inpt-spacing" />
                        <textarea type="text" placeholder="description" onChange={(e) => setDesc(e.target.value)} className="form-control iaction-inpt-spacing" />
                        <input type="number" step={0.01} min={0} placeholder="price" onChange={(e) => setPrice(e.target.value)} className="form-control" />
                    </div>
                    : null
                }

                {page === 4 ?
                    <div className="iaction-container">
                        <button onClick={modifyInventory} className="btn btn-success iaction-inpt-spacing">Update stocks</button>
                        <input type="text" placeholder="SKU" onChange={(e) => setSKU(e.target.value)} className="form-control iaction-inpt-spacing  " />
                        <input type="number" placeholder="new stock level" onChange={(e) => setStk(e.target.value)} className="form-control" />

                    </div>
                    : null
                }

                <h5 style={{ marginTop: "40px", fontWeight: "bold" }}>{disp ? msg : null}</h5>


            </div>

        </div>
    );
}