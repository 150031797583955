import React, { useState } from "react";
import { createContext } from "react";

const GlobalContext = createContext();

function GlobalProvider({children}) {
    const [user, setUser] = useState(localStorage.getItem('user') ?? ''); //to track user information 

    return (
        <GlobalContext.Provider value={{user, setUser}}>
            {children}
        </GlobalContext.Provider>
    );
}

export {GlobalContext, GlobalProvider};