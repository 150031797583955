import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from './GlobalContext';
import axios from 'axios';
import Navigation from './Navigation';
import './styles/dash.css';

export default function Dashboard() {
    const { user } = useContext(GlobalContext);
    const [data, setData] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const updateData = async () => {
            try {
                const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/grab/db/${user}`, { withCredentials: true });
                setData(resp.data);
            }
            catch (err) {
                if (err.message.includes('403')) {
                    navigate('/unauth');
                }
            }
        }

        updateData();
        setInterval(updateData, 2000);
    }, [user, navigate]);



    const displayDesc = (txt) => {
        let final = "";
        for (let i = 0; i < 15; i++) {
            if (txt[i]) {
                final += txt[i];
            }
        }
        return final + '...'
    }

    


    return (
        <div className='dash-container'>
            <Navigation />

            <h2 className='dash-wlcm'>Welcome {user}</h2>

            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>SKU</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Stocks</th>
                    </tr>
                </thead>

                <tbody>
                    {data ?
                        data.map((itm, index) =>
                            <tr key={index}>
                                <td>{itm.item}</td>
                                <td>{itm.SKU}</td>
                                <td>{displayDesc(itm.desc)}</td>
                                <td>{itm.price}</td>
                                <td>{itm.stock}</td>
                            </tr>
                        )
                        : null
                    }
                </tbody>
            </table>

        </div>
    );
}
