import './App.css';
import Login from './Auth/Login';
import Signup from './Auth/Signup';
import Dashboard from './Dashboard';
import Forbidden from './Extras/Forbidden';
import ItemAction from './InteractItems/ItemAction';
import ViewItem from './InteractItems/ItemCheck';
import Resources from './InteractItems/Resources';
import NotFound from './Extras/NotFound';
import { GlobalProvider } from './GlobalContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <GlobalProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/moditems' element={<ItemAction />} />
          <Route path='/viewitems' element={<ViewItem />} />
          <Route path='/downloads' element={<Resources />} />
          <Route path='/unauth' element={<Forbidden />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
