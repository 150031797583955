import React, { useContext, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import '../styles/extras.css';

export default function Resources() {
    const {user} = useContext(GlobalContext);
    const [msg, setMsg] = useState('');
    const [disp, setDisp] = useState(false);
    const navigate = useNavigate();

    const downloadFile = async(url, filename) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                withCredentials: true
            });
            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (err) {
            if(err.message.includes('403')) {
                navigate('/unauth');
            }
            else if(err.message.includes('500')) {
                setMsg('The inventory is currently empty');
            }
            else {
                setMsg('unable to download ' + filename + ' file');
            }
            setDisp(true);
        }
    }

    const handleInven = async() => {
       downloadFile(`${process.env.REACT_APP_API_URL}/api/inventory/data/${user}`, 'Inventory.csv');
    }

    const handleRem = async() => {
        downloadFile(`${process.env.REACT_APP_API_URL}/api/removed/gen/${user}`, 'Inventory_removed.csv');
    }

    const handleRstock = async() => {
        downloadFile(`${process.env.REACT_APP_API_URL}/api/restocks/gen/${user}`, 'Inventory_restocks.csv');
    }

    const handleSales = async() => {
        downloadFile(`${process.env.REACT_APP_API_URL}/api/sales/gen/${user}`, 'Inventory_sales.csv');
    }
    
    return (
        <div>
            <Navigation />
            
            <h3 className="rsrc-title">Download files </h3>
            
            <div className="rsrc-container">
                <button onClick={handleInven} className="btn btn-primary rsrc-btns">Current Inventory</button>
                <button onClick={handleRem} className="btn btn-primary rsrc-btns">Removed Items</button>
                <button onClick={handleRstock} className="btn btn-primary rsrc-btns">Restocks</button>
                <button onClick={handleSales} className="btn btn-primary rsrc-btns">Sales</button>
                {disp ? <h5 style={{ marginTop: "40px", fontWeight: "bold" }}>{msg}</h5> : null}
            </div>

        </div>
    );
}